import React, { SetStateAction } from 'react'
import { Navigate } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import LargeSpinner from 'components/basics/Spinners/LargeSpinner'

import { ROUTES } from 'components/sections/app/AppRoutes'
import { ALL_COMPANY_TIERS } from 'utils/constants'

import allContent from 'content/content'
import SubscriptionSetup from '../../sections/app/SubscriptionSetup/SubscriptionSetup'

const content = allContent.landingPage

/** LandingPage is needed to handle where user should go after being Authorised.
 * Show spinner while fetching userData, and handle based on companyTier and user role.
 * Is also the holding area for if user has no financeSystemId, or there's an error in fetching user data.
 */
const LandingPage: React.FC<{
    companyTier?: CompanyTierTypes
    financeSystemId?: string
    isFetchingUserData: boolean
    managerApiClient: ApolloClient<NormalizedCacheObject>
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
}> = ({ managerApiClient, isFetchingUserData, companyTier, financeSystemId, setUserData }) => {
    /** Show spinner while waiting for getUserData so app can determine what apps/pages/roles the user has access to */
    if (isFetchingUserData) {
        return (
            <div className='general-container'>
                <LargeSpinner text={content.fetchingUserData} />
            </div>
        )
    }

    if (!financeSystemId && companyTier) {
        return (
            <div className='general-container'>
                <SubscriptionSetup
                    managerApiClient={managerApiClient}
                    companyTier={companyTier}
                    setUserData={setUserData}
                />
            </div>
        )
    }

    if (financeSystemId && companyTier && ALL_COMPANY_TIERS.includes(companyTier)) {
        /** FOR NOW CRUSE SEARCH IS LANDING PAGE FOR EVERYONE */
        return <Navigate to={ROUTES.CRUISE_SEARCH} />
    } else {
        return (
            <div className='general-container'>
                <p>{content.notOnASubscription}</p>
                {/** Shouldn't be possible to have financeId and not a valid companyTier, but meh. */}
            </div>
        )
    }
}

export default LandingPage
