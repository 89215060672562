import React, { useEffect, useState } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import PriceBreakdownView from 'components/sections/cruise/PriceBreakdownView/PriceBreakdownView'
import { useApolloQuery } from 'components/hooks/useApolloQuery'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import { GET_PRICE_ITEM_BREAKDOWN } from 'graphql-queries/cruise/cruise-queries'
import { Breakdown, getBreakDownItemsByPassenger } from 'api-data-models/CabinContentModel'
import { insertDecimal2CharsFromEnd } from 'utils/string-helpers'
import { VITALLY_EVENTS } from 'utils/constants'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'

import styles from './PriceBreakdown.module.css'
import allContent from 'content/content'

const content = allContent.cruise.sailingPage.cabinGradesView.breakdown

interface PriceBreakdownProps {
    cabinGradeCode: string
    rateCode: string
    cabinGradeDescription: string
    rateCodeDescription: string
    currency: string
    cruiseId: string
    supplierCode: string
    setIsLoading?: (value: boolean) => void
    priceProps?: {
        military?: boolean
        residency?: boolean
        wifi?: boolean
        refundPolicy?: RefundPolicyType
        onBoardCredits?: number
    }
    apiClient: ApolloClient<NormalizedCacheObject>
}

interface PricingData {
    commission: string
    totalGrossPrice: string
    totalNonCommissionablePrice: string
    totalOnboardCreditPrice?: string
    totalTaxesFeesPortExpensesPrice?: string
}

export const PriceBreakdown: React.FC<PriceBreakdownProps> = (props): JSX.Element => {
    const {
        cabinGradeCode,
        rateCode,
        cabinGradeDescription,
        rateCodeDescription,
        currency,
        cruiseId,
        supplierCode,
        setIsLoading,
        priceProps,
        apiClient,
    } = props
    const [breakdownPerPassenger, setBreakdownPerPassenger] = useState<null | Record<
        string,
        Breakdown
    >>(null)
    const userContext = datadogLogs.getGlobalContext()
    const [priceData, setPriceData] = useState<PricingData | null>(null)
    const { passengerConfigurationDataAsQueryVar } = usePassengersInfo()
    const queryVariables = {
        cabinGradeCode,
        rateCode,
        cruiseId,
        supplierCode,
        passengers: {
            passengers: passengerConfigurationDataAsQueryVar,
        },
    }

    const pricePropsToCheck = {
        ...priceProps,
        onBoardCredits: priceData?.totalOnboardCreditPrice ?? 0,
    }

    useEffect(() => {
        CustomerSuccess.track({
            eventName: VITALLY_EVENTS.LIVE_PRICING_SAILING_BREAKDOWN,
            properties: {
                cruiseId: queryVariables.cruiseId,
                supplierCode: queryVariables.supplierCode,
                rateCode: queryVariables.rateCode,
                cabinGradeCode: queryVariables.cabinGradeCode,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // only run on mount

    const { result, loading, error } = useApolloQuery({
        client: apiClient,
        variables: queryVariables,
        query: GET_PRICE_ITEM_BREAKDOWN,
        source: 'PriceBreakdown - GET_PRICE_ITEM_BREAKDOWN',
    })

    useEffect(() => {
        if (setIsLoading) setIsLoading(loading)
    }, [setIsLoading, loading])

    useEffect(() => {
        if (result?.data?.cruise?.priceItems[0]?.breakdownItems) {
            const priceItem = result.data.cruise.priceItems[0]
            setBreakdownPerPassenger(getBreakDownItemsByPassenger(priceItem.breakdownItems))
            setPriceData({
                totalGrossPrice: insertDecimal2CharsFromEnd(priceItem.totalGrossPrice),
                totalNonCommissionablePrice: insertDecimal2CharsFromEnd(priceItem.totalNcfPrice),
                ...(priceItem.totalObcPrice && {
                    totalOnboardCreditPrice: insertDecimal2CharsFromEnd(priceItem.totalObcPrice),
                }),
                ...(priceItem.totalTfpePrice && {
                    totalTaxesFeesPortExpensesPrice: insertDecimal2CharsFromEnd(
                        priceItem.totalTfpePrice
                    ),
                }),
                commission: insertDecimal2CharsFromEnd(priceItem.commission),
            })
            datadogLogs.logger.info(
                `source: ${VITALLY_EVENTS.LIVE_PRICING_SAILING_BREAKDOWN}, cruiseId: ${queryVariables.cruiseId}, supplierCode: ${queryVariables.supplierCode}, rateCode: ${queryVariables.rateCode}, cabinGradeCode: ${queryVariables.cabinGradeCode}`,
                userContext
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        queryVariables.cabinGradeCode,
        queryVariables.cruiseId,
        queryVariables.rateCode,
        queryVariables.supplierCode,
        result,
    ]) // do not include userContext

    if (error) {
        return (
            <>
                <ErrorList errorsList={error} source='cabin-price-breakdown' />
                <p>
                    Using rateCode: {rateCode}, cabinGradeCode: {cabinGradeCode}, cruiseId:
                    {cruiseId}, supplierCode: {supplierCode}
                </p>
            </>
        )
    }
    if (loading) {
        return (
            <div className={styles.fetching}>
                <LargeSpinner text={content.fetching} />
            </div>
        )
    }
    if (priceData && breakdownPerPassenger) {
        return (
            <PriceBreakdownView
                commission={priceData.commission}
                breakdownPerPassenger={breakdownPerPassenger}
                currency={currency}
                totalGrossPrice={priceData.totalGrossPrice}
                cabinGradeCode={cabinGradeCode}
                rateCode={rateCode}
                cabinGradeDescription={cabinGradeDescription}
                rateCodeDescription={rateCodeDescription}
                priceProps={pricePropsToCheck}
            />
        )
    }

    return <div /> // needed for the ansyc state update of prcieData which is called after loading becomes false
}
