import React, { SetStateAction, useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import AdminLayout from 'components/layouts/admin/AdminLayout/AdminLayout'
import GroupManagementLayout from 'components/layouts/admin/GroupManagementLayout/GroupManagementLayout'
import UserManagementLayout from 'components/layouts/admin/UserManagementLayout/UserManagementLayout'
import SalesChannelManagementLayout from 'components/layouts/admin/SalesChannelManagementLayout/SalesChannelManagementLayout'
import EditSalesChannelLayout from 'components/layouts/admin/EditSalesChannelLayout/EditSalesChannelLayout'
import ReportingLayout from 'components/layouts/admin/ReportingLayout/ReportingLayout'
import SubscriptionsManagementLayout from 'components/layouts/admin/SubscriptionsLayout/SubscriptionsLayout'
import CompanyConfigurationLayout from 'components/layouts/admin/CompanyConfigurationLayout/CompanyConfigurationLayout'
import SaaSSignUpLayout from 'components/layouts/app/SaaSSignUpLayout/SaaSSignUpLayout'
import LoginLayout from 'components/layouts/cognito/LoginLayout/LoginLayout'
import ForgotPasswordLayout from 'components/layouts/cognito/ForgotPasswordLayout/ForgotPasswordLayout'
import ResetPasswordLayout from 'components/layouts/cognito/ResetPasswordLayout/ResetPasswordLayout'
import LandingPage from 'components/pages/app/LandingPage'
import CruiseSearchPage from 'components/pages/cruise/SearchPage'
import CruiseResultsPage from 'components/pages/cruise/ResultsPage'
import CruiseSailingPage from 'components/pages/cruise/SailingPage'
import CruiseCabinPage from 'components/pages/cruise/CabinPage'
import OrderPage from 'components/pages/order/OrderPage'
import OrderConfirmationPage from 'components/pages/order/OrderConfirmationPage'
import OrderPreviewPage from 'components/pages/order/OrderPreviewPage/OrderPreviewPage'
import OrderImportPage from 'components/pages/order/OrderImportPage/OrderImportPage'
import AllOrdersPage from 'components/pages/order/OrdersPage/OrdersPage'
import FeaturebaseEmbedPage from 'components/pages/app/FeaturebaseEmbedPage'
import TimeoutLayout from 'components/layouts/app/TimeoutLayout/TimeoutLayout'
import InactiveTenantLayout from 'components/layouts/app/InactiveTenantLayout/InactiveTenantLayout'
import ApiUserWarningLayout from 'components/layouts/app/ApiUserWarningLayout/ApiUserWarningLayout'
import ProtectedRouteWrapper from './ProtectedRouteWrapper/ProtectedRouteWrapper'
import CrashErrorLayout from 'components/layouts/app/CrashErrorLayout/CrashErrorLayout'
import createApolloClient from 'utils/apollo-client/create-apollo-client'
import { ALL_COMPANY_TIERS, COGNITO_ACTIONS, PAID_COMPANY_TIERS, USER_ROLES } from 'utils/constants'
import packageJson from '../../../../package.json'
import { FeatureToggleContext } from 'App'
import { CruisesMetaData } from 'api-data-models/CruisesContentModel'

export const ROUTES = {
    ROOT: '/',
    ADMIN_DASHBOARD: '/admin',
    USER_MANAGEMENT: '/admin/user-management',
    SUBSCRIPTIONS: '/admin/subscriptions',
    GROUP_MANAGEMENT: '/admin/group-management',
    SALES_CHANNEL_MANAGEMENT: '/admin/sales-channel-management',
    EDIT_SALES_CHANNEL_MANAGEMENT: '/admin/edit-sales-channel',
    COMPANY_CONFIGURATION: '/admin/company-configuration',
    MI_REPORTING: '/admin/mi-reporting',
    CRUISE_SEARCH: '/cruise/search',
    CRUISE_RESULTS: '/cruise/results',
    CRUISE_SAILING: '/cruise/sailing',
    CRUISE_CABIN: '/cruise/cabin',
    ORDER: '/order',
    ORDER_CONFIRMATION: '/order-confirmation',
    ORDER_IMPORT_PREVIEW: '/order-import-preview',
    ORDER_IMPORT: '/order-import',
    ALL_ORDERS: '/orders',
    LOGIN: '/login',
    SIGNUP: '/sign-up',
    FEATURE_PORTAL: '/featureportal',
    FEATURE_PORTAL_CHANGELOG: '/featureportal/changelog',
    NEW_PASSWORD: '/new-password',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    TIMEOUT: '/session-timed-out',
    INACTIVE_TENANT: '/inactive-tenant',
    API_USER_WARNING: '/api-user-warning',
    VERSION: '/version',
}

export const ROUTES_NONE_LOGGED_IN_USER_CAN_ACCESS = [
    ROUTES.LOGIN,
    ROUTES.FORGOT_PASSWORD,
    ROUTES.RESET_PASSWORD,
    ROUTES.TIMEOUT,
    ROUTES.VERSION,
    ROUTES.SIGNUP,
    ROUTES.SIGNUP + '/',
    ROUTES.INACTIVE_TENANT,
    ROUTES.API_USER_WARNING,
]

type AppRoutesProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    cleanUpAppOnLogout(): void
    cruisesMetaData: CruisesMetaData
    setUserData: React.Dispatch<SetStateAction<GlobalContextUserData>>
    userData: GlobalContextUserData
    isFetchingUserData: boolean
}

const AppRoutes: React.FC<AppRoutesProps> = ({
    apiClient,
    cleanUpAppOnLogout,
    cruisesMetaData,
    setUserData,
    userData,
    isFetchingUserData,
}) => {
    const managerUrl = process.env.REACT_APP_CONNECT_MANAGER_SERVICE_URL + '/manager'
    const managerApiClient = createApolloClient(managerUrl)
    const featureToggles = useContext(FeatureToggleContext)
    const turnOnCompanyConfiguration = featureToggles.TURN_ON_COMPANY_CONFIG
    const { userRoles, userId, companyTier, financeSystemId, challengeName } = userData
    const isAuthorised = !!userId
    const isAdminUser = userRoles.includes(USER_ROLES.ADMIN)

    return (
        <Routes>
            <Route
                path={ROUTES.LOGIN}
                element={
                    !isAuthorised || challengeName === COGNITO_ACTIONS.new_password ? (
                        <LoginLayout setUserData={setUserData} />
                    ) : (
                        <Navigate to={ROUTES.ROOT} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.FEATURE_PORTAL}
                element={isAuthorised ? <FeaturebaseEmbedPage /> : <Navigate to={ROUTES.LOGIN} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.FEATURE_PORTAL_CHANGELOG}
                element={isAuthorised ? <FeaturebaseEmbedPage /> : <Navigate to={ROUTES.LOGIN} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.SIGNUP}
                element={
                    !isAuthorised ? (
                        <SaaSSignUpLayout setUserData={setUserData} />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.FORGOT_PASSWORD}
                element={<ForgotPasswordLayout />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.RESET_PASSWORD}
                element={<ResetPasswordLayout setUserData={setUserData} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.TIMEOUT}
                element={<TimeoutLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.INACTIVE_TENANT}
                element={<InactiveTenantLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.API_USER_WARNING}
                element={<ApiUserWarningLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.ROOT}
                element={
                    /** Allow Authorised users on to Landing page - handles when user when no financeId */
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={'fake-so-landing-page-can-set-financeID'}
                        companyTier={companyTier}
                        companyTierRequirement={ALL_COMPANY_TIERS}
                    >
                        <LandingPage
                            managerApiClient={managerApiClient}
                            financeSystemId={financeSystemId}
                            companyTier={companyTier}
                            isFetchingUserData={isFetchingUserData}
                            setUserData={setUserData}
                        />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.ADMIN_DASHBOARD}
                element={
                    isAuthorised && isAdminUser ? (
                        <AdminLayout
                            apiClient={managerApiClient}
                            financeSystemId={financeSystemId}
                            companyTier={companyTier}
                        />
                    ) : (
                        <Navigate to={ROUTES.ROOT} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.USER_MANAGEMENT}
                element={
                    isAuthorised && isAdminUser ? (
                        <UserManagementLayout
                            apiClient={managerApiClient}
                            companyTier={companyTier}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.SUBSCRIPTIONS}
                element={
                    process.env.REACT_APP_CHARGEBEE_PUBLIC_KEY && isAuthorised && isAdminUser ? (
                        <SubscriptionsManagementLayout
                            companyTier={companyTier}
                            financeSystemId={financeSystemId}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.GROUP_MANAGEMENT}
                element={
                    isAuthorised &&
                    companyTier &&
                    PAID_COMPANY_TIERS.includes(companyTier) &&
                    isAdminUser ? (
                        <GroupManagementLayout
                            apiClient={managerApiClient}
                            companyTier={companyTier}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.SALES_CHANNEL_MANAGEMENT}
                element={
                    isAuthorised &&
                    companyTier &&
                    PAID_COMPANY_TIERS.includes(companyTier) &&
                    isAdminUser ? (
                        <SalesChannelManagementLayout
                            apiClient={managerApiClient}
                            companyTier={companyTier}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.EDIT_SALES_CHANNEL_MANAGEMENT} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    isAuthorised &&
                    companyTier &&
                    PAID_COMPANY_TIERS.includes(companyTier) &&
                    isAdminUser ? (
                        <EditSalesChannelLayout
                            apiClient={managerApiClient}
                            companyTier={companyTier}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.EDIT_SALES_CHANNEL_MANAGEMENT}/:salesChannelId`}
                element={
                    isAuthorised &&
                    companyTier &&
                    PAID_COMPANY_TIERS.includes(companyTier) &&
                    isAdminUser ? (
                        <EditSalesChannelLayout
                            apiClient={managerApiClient}
                            companyTier={companyTier}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.COMPANY_CONFIGURATION}`}
                element={
                    isAuthorised &&
                    companyTier &&
                    PAID_COMPANY_TIERS.includes(companyTier) &&
                    isAdminUser &&
                    turnOnCompanyConfiguration ? (
                        <CompanyConfigurationLayout
                            apiClient={managerApiClient}
                            companyTier={companyTier}
                        />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.MI_REPORTING}`}
                element={
                    isAuthorised &&
                    companyTier &&
                    PAID_COMPANY_TIERS.includes(companyTier) &&
                    isAdminUser ? (
                        <ReportingLayout companyTier={companyTier} />
                    ) : (
                        <Navigate to={ROUTES.LOGIN} />
                    )
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.CRUISE_SEARCH}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={ALL_COMPANY_TIERS}
                    >
                        <CruiseSearchPage cruisesMetaData={cruisesMetaData} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.CRUISE_RESULTS} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={ALL_COMPANY_TIERS}
                    >
                        <CruiseResultsPage cruisesMetaData={cruisesMetaData} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.CRUISE_RESULTS}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <CruiseResultsPage cruisesMetaData={cruisesMetaData} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.CRUISE_SAILING} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <CruiseSailingPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.CRUISE_SAILING}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <CruiseSailingPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.CRUISE_CABIN} // Route without params exists because the page will handle this scenario and tell the user the issue
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <CruiseCabinPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.CRUISE_CABIN}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <CruiseCabinPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            {/** Route without params exists because the page will handle this scenario and tell the user the issue */}
            <Route
                path={ROUTES.ORDER}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <OrderPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.ORDER}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <OrderPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />

            <Route
                path={`${ROUTES.ORDER_IMPORT_PREVIEW}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <OrderPreviewPage apiClient={apiClient} />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.ORDER_IMPORT}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <OrderImportPage />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.ALL_ORDERS}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <AllOrdersPage />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            {/** Route without params exists because the page will handle this scenario and tell the user the issue */}
            <Route
                path={ROUTES.ORDER_CONFIRMATION}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <OrderConfirmationPage />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={`${ROUTES.ORDER_CONFIRMATION}/:params`}
                element={
                    <ProtectedRouteWrapper
                        isFetchingUserData={isFetchingUserData}
                        financeSystemId={financeSystemId}
                        companyTier={companyTier}
                        companyTierRequirement={PAID_COMPANY_TIERS}
                    >
                        <OrderConfirmationPage />
                    </ProtectedRouteWrapper>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            <Route
                path={ROUTES.VERSION}
                element={
                    <div className='general-container'>
                        <p>{`version:${packageJson.version}`}</p>
                    </div>
                }
                errorElement={<CrashErrorLayout cleanUpAppOnLogout={cleanUpAppOnLogout} />}
            />
            {/** Wild card to catch all routes that don't exist and divert to root */}
            <Route path={'*'} element={<Navigate to={ROUTES.LOGIN} replace />} />
        </Routes>
    )
}

export default AppRoutes
