import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Button from 'components/basics/Button/Button'
import Header from 'components/blocks/Header/Header'
import Image from 'components/basics/Image/Image'
import ImportModal from './ImportModal/ImportModal'
import Menu from 'components/blocks/Menu/Menu'
import Text from 'components/basics/Text/Text'
import { ROUTES } from 'components/sections/app/AppRoutes'
import {
    APP_COMPANY_TIERS,
    CHARGEBEE_SUBSCRIPTION_NAMES,
    PAID_COMPANY_TIERS,
    USER_ROLES,
} from 'utils/constants'
import { isTestEmailDomain } from 'utils/testing-handlers'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { FeatureToggleContext } from 'App'

import allContent from 'content/content'
import styles from './HeaderContent.module.css'

const headerContent = allContent.header
const userMenuContent = allContent.header.userMenu

type HeaderContentProps = {
    apiClient?: ApolloClient<NormalizedCacheObject>
    isAuthorised: null | boolean
    logUserOut({ to }: { to: string }): void
    companyTier?: CompanyTierTypes
    userEmail: string
    userRoles: UserRole[]
    financeSystemId?: string
    isFetchingUserData: boolean
    hideUserMenu?: boolean
}
const HeaderSection: React.FC<HeaderContentProps> = ({
    apiClient,
    isAuthorised,
    logUserOut,
    companyTier,
    userEmail,
    userRoles,
    financeSystemId,
    isFetchingUserData,
    hideUserMenu = false,
}) => {
    const featureToggles = useContext(FeatureToggleContext)
    const navigate = useNavigate()
    const [isImportOrderModalOpen, setImportOrderModalOpen] = useState(false)
    const { companyName, allData: userContext } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )
    const importOrderEnabled = featureToggles.TURN_ON_ORDERS_IMPORT || isTestEmailDomain(userEmail)
    const location = useLocation().pathname

    function onClickLogOut(): void {
        datadogLogs.logger.info(`Logging out user: ${userEmail}`, { userContext: userContext })
        logUserOut({ to: ROUTES.LOGIN })
    }

    return (
        <Header>
            <a href={ROUTES.ROOT} className={styles.logo}>
                <Image className={styles.logo} src={headerContent.logo} alt='TravelTek logo' />
            </a>
            {location !== ROUTES.LOGIN && !isAuthorised && !hideUserMenu && (
                <Button
                    showSpinner={isFetchingUserData}
                    id='log-in-button'
                    className={styles['nav-button']}
                    text={headerContent.loginButton}
                    flavour='tertiary'
                    onDarkBackground={true}
                    type='button'
                    onClick={(): void => navigate(ROUTES.LOGIN)}
                />
            )}
            {location === ROUTES.LOGIN && !isAuthorised && !hideUserMenu && (
                <Button
                    id='sign-up-button'
                    className={styles['nav-button']}
                    text={headerContent.signupButton}
                    flavour='tertiary'
                    onDarkBackground={true}
                    type='button'
                    onClick={(): void => navigate(ROUTES.SIGNUP)}
                />
            )}
            {isAuthorised && (
                <div className={styles['nav-container']}>
                    {financeSystemId && companyTier && APP_COMPANY_TIERS.includes(companyTier) && (
                        <Button
                            id='cruise-search-button'
                            className={styles['nav-button']}
                            text={headerContent.cruiseSearchButton}
                            flavour='tertiary'
                            onDarkBackground={true}
                            type='button'
                            onClick={(): void => navigate(ROUTES.CRUISE_SEARCH)}
                        />
                    )}
                    {financeSystemId &&
                        importOrderEnabled &&
                        companyTier &&
                        APP_COMPANY_TIERS.includes(companyTier) &&
                        apiClient && (
                            <>
                                <ImportModal
                                    isOpen={isImportOrderModalOpen}
                                    onClose={(): void => setImportOrderModalOpen(false)}
                                    returnFocusId='order-import-button'
                                    apiClient={apiClient}
                                />
                                <Button
                                    id='order-import-button'
                                    className={styles['nav-button']}
                                    text={headerContent.importButton}
                                    flavour='tertiary'
                                    onDarkBackground={true}
                                    type='button'
                                    onClick={(): void => setImportOrderModalOpen(true)}
                                />
                            </>
                        )}
                    {financeSystemId &&
                        companyTier &&
                        PAID_COMPANY_TIERS.includes(companyTier) &&
                        APP_COMPANY_TIERS.includes(companyTier) &&
                        featureToggles.TURN_ON_ORDERS_PAGE && (
                            <Button
                                className={styles['nav-button']}
                                text={headerContent.ordersButton}
                                flavour='tertiary'
                                onDarkBackground={true}
                                type='button'
                                onClick={(): void => navigate(ROUTES.ALL_ORDERS)}
                            />
                        )}
                    {userRoles?.includes(USER_ROLES.ADMIN) && (
                        <Button
                            id='admin-settings-button'
                            className={styles['nav-button']}
                            text={headerContent.adminSettingsButton}
                            flavour='tertiary'
                            onDarkBackground={true}
                            type='button'
                            onClick={(): void => navigate(ROUTES.ADMIN_DASHBOARD)}
                        />
                    )}
                    <Menu
                        id={userMenuContent.label}
                        buttonText={userMenuContent.label}
                        onDarkBackground={true}
                        doubleTopMargin={true}
                        iconName='UserCircle'
                        options={[
                            ...(companyName || userEmail
                                ? [
                                      {
                                          jsxElement: (
                                              <div>
                                                  {companyName && (
                                                      <Text color='white'>{companyName}</Text>
                                                  )}
                                                  {companyName && companyTier && <br />}
                                                  {companyTier && (
                                                      <Text color='white'>
                                                          {
                                                              CHARGEBEE_SUBSCRIPTION_NAMES[
                                                                  companyTier
                                                              ]
                                                          }
                                                      </Text>
                                                  )}
                                                  {companyTier && userEmail && <br />}
                                                  {userEmail && (
                                                      <Text color='white' weight='bold'>
                                                          {userEmail}
                                                      </Text>
                                                  )}
                                              </div>
                                          ),
                                          label: 'company-name',
                                      },
                                  ]
                                : []),
                            {
                                jsxElement: (
                                    <Button
                                        flavour='secondary'
                                        text={userMenuContent.logoutButton}
                                        type='button'
                                        onClick={onClickLogOut}
                                    />
                                ),
                                label: userMenuContent.logoutButton,
                            },
                        ]}
                    />
                </div>
            )}
        </Header>
    )
}

export default HeaderSection
