import { useState, useEffect } from 'react'
import callApi from 'services/callApi/callApi.service'
import { HTTP_METHODS } from 'utils/constants'
import { createGenericError } from 'utils/api-errors-helpers/create-generic-error'
import { getRestErrorMessage } from 'utils/api-errors-helpers/get-rest-error-message'
import { datadogLogs } from '@datadog/browser-logs'
import { checkAndPerformUserSessionRefreshIfNeeded } from 'utils/cognito-helpers/check-and-perform-user-session-refresh-if-needed'
import { useNavigate } from 'react-router-dom'
import { getNetworkErrorMessage } from 'utils/api-errors-helpers/get-network-error-message'
import allContent from 'content/content'

const content = allContent.error.network

type UseRestResponse = {
    /** boolean to indicate whether query is still ongoing */
    loading: boolean
    /** object containing api response */
    result?: Record<string, any>
    /** our own CustomApiError object we convert error response into, if there's an error it always returns at least one CustomApiError object */
    error: CustomApiError[] | null
    /** function used to trigger fetch to be repeated */
    refetch: () => void
}

export type UseRestOptions = {
    /** url */
    url: string
    /** parameters to be passed in body of methods other than GET */
    variables?: Record<string, any>
    /** HTTP method */
    method: keyof typeof HTTP_METHODS
    /** hooks can't be put inside if statements, so use this variable based on logic instead */
    skip?: boolean
    /** unique string value to group api logging */
    source: string
    /** used to trigger browser cache of response */
    cache?: boolean
    /** sets max age in milliseconds allowed for cache of response */
    cacheMaxAge?: number
}

/** useRest: provides loading, result, error state of callApi service, along with refetch function
 * Takes params of url, variables, url to consume, also with skip boolean used to prevent trigger.
 * @param { UseRestOptions } options
 * @returns { UseRestResponse } */
export const useRest = ({
    variables,
    url,
    method,
    source,
    skip = false,
    cache,
    cacheMaxAge,
}: UseRestOptions): UseRestResponse => {
    const [result, setResult] = useState<Record<string, any> | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(!skip)
    const [error, setError] = useState<CustomApiError[] | null>(null)
    const [newFetch, setNewFetch] = useState<boolean>(true)
    const navigate = useNavigate()
    const userContext = datadogLogs.getGlobalContext()

    useEffect(() => {
        if (skip || !newFetch) {
            return
        }
        const handleUserSession = checkAndPerformUserSessionRefreshIfNeeded(navigate)
        let correlationId = ''
        handleUserSession.then(() => {
            callApi({ url, method, variables, cache, cacheMaxAge })
                .then((response) => {
                    correlationId = response.headers?.get('x-correlation-id') || ''
                    return response.json()
                })
                .then((data) => {
                    let dataString = 'undefined'
                    if (data) {
                        setResult(data)
                        dataString =
                            // DataDog char limit
                            JSON.stringify(data).length > 225280
                                ? 'Data too large to show'
                                : JSON.stringify(data)
                    }
                    datadogLogs.logger.info(
                        `(DUPLICATE? of Call-Api-Service) Response (rest) source: ${source}, correlationId: ${correlationId}, Fetch-variables: ${JSON.stringify(
                            variables
                        )}, Fetch-response-data: ${dataString}`,
                        {
                            userContext,
                            correlation_id: correlationId,
                            'x-correlation-id': correlationId,
                        }
                    )
                    setLoading(false)
                })
                .catch((error) => {
                    let errorMessage
                    if (error.cause?.body?.detail) {
                        errorMessage = getRestErrorMessage({
                            errors: error.cause,
                            source: source,
                            variables: variables,
                            correlationId: correlationId,
                        })
                    } else if (error.cause?.status) {
                        errorMessage = [
                            createGenericError({
                                description: getNetworkErrorMessage(error.cause.status),
                                source,
                                error,
                            }),
                        ]
                    } else {
                        errorMessage = [
                            createGenericError({
                                description: content.unknown,
                                source: source,
                                error,
                            }),
                        ]
                    }
                    // logging is done by getRestErrorMessage + createGenericError
                    setError(errorMessage)
                    setLoading(false)
                })
            setNewFetch(false)
        })
        // No 'else' here to catch refresh session error because if because we log in the refresh session function

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cache, cacheMaxAge, method, navigate, newFetch, skip, source, url, variables]) // do not include userContext

    const refetch = (): void => {
        setNewFetch(true)
        setLoading(true)
        setResult(undefined)
        setError(null)
    }

    return { result, loading, error, refetch }
}
