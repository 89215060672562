import React, { useContext } from 'react'

import Heading from 'components/basics/Heading/Heading'
import Link from 'components/basics/Link/Link'
import List from 'components/basics/List/List'
import ListItem from 'components/basics/List/ListItem'
import Text from 'components/basics/Text/Text'
import { FeatureToggleContext } from 'App'
import { ROUTES } from 'components/sections/app/AppRoutes'
import { COMPANY_TIER_NAMES, PAID_COMPANY_TIERS } from 'utils/constants'

import styles from './NavigationPanel.module.css'
import allContent from 'content/content'
// import { datadogLogs } from '@datadog/browser-logs'

const content = allContent.admin.navigationPanel

type NavigationProps = {
    companyTier?: CompanyTierTypes
}

const NavigationPanel: React.FC<NavigationProps> = ({ companyTier }: NavigationProps) => {
    const featureToggle = useContext(FeatureToggleContext)

    const turnOnUserGroupManagement = companyTier && PAID_COMPANY_TIERS.includes(companyTier)
    const turnOnCompanyConfiguration =
        companyTier &&
        featureToggle.TURN_ON_COMPANY_CONFIG &&
        PAID_COMPANY_TIERS.includes(companyTier)
    const turnOnSalesChannels = companyTier && PAID_COMPANY_TIERS.includes(companyTier)
    const turnOnReporting = companyTier && PAID_COMPANY_TIERS.includes(companyTier)
    const turnOnSubscriptionManage =
        (process.env.REACT_APP_CHARGEBEE_PUBLIC_KEY &&
            companyTier === COMPANY_TIER_NAMES.CRUISE_PLUS_TRIAL) ||
        companyTier === COMPANY_TIER_NAMES.CRUISE_PLUS ||
        companyTier === COMPANY_TIER_NAMES.CRUISE_STARTER

    return (
        <nav className={styles.container}>
            <div className={styles.heading}> </div>
            <div>
                <List className={styles.content}>
                    <ListItem listKey='home-link' className={styles['list-item']}>
                        <Link to={ROUTES.ADMIN_DASHBOARD}>
                            <Heading heading='2' colorOverride='tertiary-blue'>
                                {content.dashboard}
                            </Heading>
                        </Link>
                    </ListItem>
                    {turnOnSubscriptionManage &&
                        process.env.REACT_APP_ENVIRONMENT_NAME !== 'production' && (
                            <ListItem listKey='subscriptionManagement'>
                                <div className={styles['list-item']}>
                                    <Heading heading='3'>{content.subscriptionManagement}</Heading>
                                </div>
                                <List className={styles.list}>
                                    <ListItem listKey='subscriptionManagementLink'>
                                        <Link className={styles.link} to={ROUTES.SUBSCRIPTIONS}>
                                            <Text weight='bold' color='tertiary-blue'>
                                                {content.subscriptionManagement}
                                            </Text>
                                        </Link>
                                    </ListItem>
                                </List>
                            </ListItem>
                        )}
                    <ListItem listKey='agentConnect'>
                        <div className={styles['list-item']}>
                            <Heading heading='3'>{content.userManagement}</Heading>
                        </div>
                        <List className={styles.list}>
                            <ListItem listKey='userManagement'>
                                <Link className={styles.link} to={ROUTES.USER_MANAGEMENT}>
                                    <Text weight='bold' color='tertiary-blue'>
                                        {content.usersManagement}
                                    </Text>
                                </Link>
                            </ListItem>
                            {turnOnUserGroupManagement && (
                                <ListItem listKey='groupManagement'>
                                    <Link className={styles.link} to={ROUTES.GROUP_MANAGEMENT}>
                                        <Text weight='bold' color='tertiary-blue'>
                                            {content.groupManagement}
                                        </Text>
                                    </Link>
                                </ListItem>
                            )}
                        </List>
                    </ListItem>
                    {turnOnSalesChannels && (
                        <ListItem listKey='salesChannel'>
                            <div className={styles['list-item']}>
                                <Heading heading='3'>{content.salesChannel}</Heading>
                            </div>
                            <List>
                                <ListItem listKey='salesChannelManagement'>
                                    <Link
                                        className={styles.link}
                                        to={ROUTES.SALES_CHANNEL_MANAGEMENT}
                                    >
                                        <Text weight='bold' color='tertiary-blue'>
                                            {content.salesChannelManagement}
                                        </Text>
                                    </Link>
                                </ListItem>
                            </List>
                        </ListItem>
                    )}
                    {turnOnCompanyConfiguration && (
                        <ListItem listKey='companyConfiguration'>
                            <div className={styles['list-item']}>
                                <Heading heading='3'>{content.companyConfiguration}</Heading>
                            </div>
                            <List>
                                <ListItem listKey='companyConfiguration'>
                                    <Link className={styles.link} to={ROUTES.COMPANY_CONFIGURATION}>
                                        <Text weight='bold' color='tertiary-blue'>
                                            {content.companyConfiguration}
                                        </Text>
                                    </Link>
                                </ListItem>
                            </List>
                        </ListItem>
                    )}
                    {turnOnReporting && (
                        <ListItem listKey='reporting'>
                            <div className={styles['list-item']}>
                                <Heading heading='3'>{content.reporting}</Heading>
                            </div>
                            <List>
                                <ListItem listKey='reporting'>
                                    <Link className={styles.link} to={ROUTES.MI_REPORTING}>
                                        <Text weight='bold' color='tertiary-blue'>
                                            {content.reportingBooking}
                                        </Text>
                                    </Link>
                                </ListItem>
                            </List>
                        </ListItem>
                    )}
                    {/*<ListItem listKey='cruiseConnect'>*/}
                    {/*    <div className={styles['list-item']}>*/}
                    {/*        <Heading heading='3'>{content.cruiseConnect}</Heading>*/}
                    {/*    </div>*/}
                    {/*    <List>*/}
                    {/*        <ListItem listKey='apiDocumentation'>*/}
                    {/*            <Link*/}
                    {/*                className={styles.link}*/}
                    {/*                href={content.apiDocumentationURL}*/}
                    {/*                target='_blank'*/}
                    {/*                onClick={(): void =>*/}
                    {/*                    datadogLogs.logger.info('API docs link clicked (nav panel)')*/}
                    {/*                }*/}
                    {/*            >*/}
                    {/*                <Text weight='bold' color='tertiary-blue'>*/}
                    {/*                    {content.apiDocumentation}*/}
                    {/*                </Text>*/}
                    {/*            </Link>*/}
                    {/*        </ListItem>*/}
                    {/*    </List>*/}
                    {/*</ListItem>*/}
                </List>
            </div>
        </nav>
    )
}

export default NavigationPanel
