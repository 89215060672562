import React from 'react'

import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import Heading from 'components/basics/Heading/Heading'
import GettingStartedSection from './GettingStartedSection/GettingStartedSection'
import Misc from './Misc/Misc'
// import ApiCredentialsSection from './ApiCredentials/ApiCredentials'
// import CruiseConnectDescription from './CruiseConnectSection/Description/CruiseConnectDescription'
import { PAID_COMPANY_TIERS } from 'utils/constants'
import styles from './AdminLanding.module.scss'
import allContent from 'content/content'

const content = allContent.admin.adminLanding

type AdminLandingProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    companyTier?: CompanyTierTypes
}

const AdminLanding: React.FC<AdminLandingProps> = ({ apiClient, companyTier }) => {
    const onPaidTier = !!companyTier && PAID_COMPANY_TIERS.includes(companyTier)

    // // ALL API BITS OFF - No one should see it just now
    // const apiBits = (
    // {/* CRUISE CONNECT API SECTION (PAID) */}
    //   <div className={styles.item}>
    //     <div className={styles['horizontal-boxes']}>
    //       <CruiseConnectDescription />
    //       <ApiCredentialsSection apiClient={apiClient} />
    //     </div>
    //   </div>

    // {/* CRUISE CONNECT SECTION (FREE) */}
    // <div className={styles.item}>
    //   <div className={styles['horizontal-boxes']}>
    //     <CruiseConnectDescription />
    //     <ApiCredentialsSection apiClient={apiClient} />
    //   </div>
    //   <Upgrade
    //    disabled={false}
    //    upgradeIsProcessing={cruiseConnectProcessing}
    //    apiClient={apiClient}
    //    content={content.cruiseConnect.upgradeSection}
    //    productName={'CRUISECONNECT'}
    //   />
    // </div>)

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            {/*{onPaidTier && <YoutubeEmbed embedId='BR6CSCQ9GwU' /> } // leave here for demo of how video can be added*/}
            <GettingStartedSection onPaidTier={onPaidTier} />
            {!onPaidTier && <Misc apiClient={apiClient} />}
        </div>
    )
}

export default AdminLanding
