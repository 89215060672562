import { gql } from '@apollo/client'

/* This end point lives on App Sync */
export const SEND_EMAIL = gql`
    mutation sendEmail(
        $recipient: String!
        $template_name: String!
        $template_data: TemplateDataInput!
    ) {
        sendEmail(
            recipient: $recipient
            template_name: $template_name
            template_data: $template_data
        ) {
            recipient
        }
    }
`
