import React, { useState, useEffect, SetStateAction, useRef } from 'react'
import { Controller, useForm, UseFormReset } from 'react-hook-form'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'
import { RenderInputComponentProps } from 'react-autosuggest'

import Card from 'components/blocks/Card/Card'
import Heading from 'components/basics/Heading/Heading'
import Button from 'components/basics/Button/Button'
import Text from 'components/basics/Text/Text'
import TextInput from 'components/basics/Input/TextInput/TextInput'
import Autosuggest from 'components/blocks/Autosuggest/Autosuggest'
import LabelledInput from 'components/blocks/LabelledInput/LabelledInput'
import FieldError from 'components/basics/FieldError/FieldError'
import Modal from 'components/blocks/Modal/Modal'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import TablePaginationButtons from 'components/blocks/TablePaginationButtons/TablePaginationButtons'
import SalesChannelList from './SalesChannelList/SalesChannelList'
import { LIST_SALES_CHANNELS, CREATE_SALES_CHANNEL } from 'graphql-queries/admin/admin-queries'
import { PAID_COMPANY_TIERS, REGEX } from 'utils/constants'
import { stringArrayFilterAndSortByMatcher } from 'utils/stringArrayFilterAndSortByMatcher'

import styles from './SalesChannelManagement.module.scss'

import allContent from 'content/content'
const content = allContent.admin.salesChannelManagementPage

const SALES_CHANNELS_PER_PAGE = 10
const NUMBER_OF_SUGGESTIONS = 3

type SalesChannelManagementProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
    companyTier?: CompanyTierTypes
}

export type SalesChannelAPIResponse = {
    salesChannelId: string
    title: string
    userGroups: {
        title: string
        userGroupId: string
    }[]
}

type CreateSalesChannelFormData = {
    title: string
}

export function getSalesChannelsList(
    apiClient: ApolloClient<NormalizedCacheObject>,
    setSalesChannelData: React.Dispatch<SetStateAction<SalesChannelAPIResponse[] | null>>,
    setApiGetErrorMessage: React.Dispatch<SetStateAction<string | null>>,
    setFetchingSalesChannels: React.Dispatch<SetStateAction<boolean>>
): void {
    setFetchingSalesChannels(true)
    apiClient
        .query({ query: LIST_SALES_CHANNELS })
        .then((response) => {
            if (response?.data?.salesChannels) {
                const salesChannelsAPIResponseData = response.data.salesChannels
                setSalesChannelData(sortByTitle(salesChannelsAPIResponseData))
                setFetchingSalesChannels(false)
                datadogLogs.logger.info(
                    `Query LIST_SALES_CHANNELS successfully got salesChannels list: ${JSON.stringify(
                        salesChannelsAPIResponseData
                    )}`
                )
            }
        })
        .catch((error) => {
            setApiGetErrorMessage(content.errors.fetchingSalesChannels)
            setFetchingSalesChannels(false)
            datadogLogs.logger.error('Query LIST_SALES_CHANNELS - error:', {}, error)
        })
}

export function createSalesChannel(
    apiClient: ApolloClient<NormalizedCacheObject>,
    title: string,
    setNewSalesChannelData: React.Dispatch<SetStateAction<SalesChannelAPIResponse | null>>,
    setApiCreateErrorMessage: React.Dispatch<SetStateAction<string | null>>,
    setCreatingSalesChannel: React.Dispatch<SetStateAction<boolean>>,
    setIsModalOpen: React.Dispatch<SetStateAction<boolean>>
): void {
    setCreatingSalesChannel(true)
    apiClient
        .mutate({
            mutation: CREATE_SALES_CHANNEL,
            variables: {
                title: title,
            },
        })
        .then((response) => {
            if (response?.data?.createSalesChannel) {
                setCreatingSalesChannel(false)
                const createSalesChannelAPIResponseData = response.data.createSalesChannel
                setNewSalesChannelData(createSalesChannelAPIResponseData)
                datadogLogs.logger.info(
                    `Mutation CREATE_SALES_CHANNEL successfully got salesChannels list: ${JSON.stringify(
                        createSalesChannelAPIResponseData
                    )}`
                )
            }
        })
        .catch((error) => {
            setApiCreateErrorMessage(content.errors.createSalesChannel)
            setCreatingSalesChannel(false)
            datadogLogs.logger.error('Mutation CREATE_SALES_CHANNEL - error:', {}, error)
        })
    setIsModalOpen(false)
}

export function sortByTitle(salesChannels: SalesChannelAPIResponse[]): SalesChannelAPIResponse[] {
    return salesChannels.sort((a, b) => a.title.localeCompare(b.title))
}

export function handlePageClick(
    selectedPage: number,
    setCurrentPage: React.Dispatch<SetStateAction<number>>
): void {
    setCurrentPage(selectedPage)
}
export function salesChannelsToDisplay(
    salesChannelsData: SalesChannelAPIResponse[],
    page: number
): SalesChannelAPIResponse[] {
    const startIndex = (page - 1) * SALES_CHANNELS_PER_PAGE
    const endIndex = startIndex + SALES_CHANNELS_PER_PAGE

    return salesChannelsData.slice(startIndex, endIndex)
}

export function handleCloseModal(
    setIsModalOpen: React.Dispatch<SetStateAction<boolean>>,
    reset: UseFormReset<{
        title: string
    }>,
    buttonRef: React.MutableRefObject<HTMLButtonElement | null>
): void {
    setIsModalOpen(false)
    reset()
    if (buttonRef.current) {
        buttonRef.current.focus()
    }
}

export function handleOnAddSalesChannelClick(
    setApiErrorMessage: React.Dispatch<SetStateAction<string | null>>,
    setIsModalOpen: React.Dispatch<SetStateAction<boolean>>,
    setCreateSalesChannelSuccess: React.Dispatch<SetStateAction<boolean>>,
    reset: UseFormReset<{
        title: string
    }>
): void {
    setApiErrorMessage(null)
    setCreateSalesChannelSuccess(false)
    setIsModalOpen(true)
    reset()
}

export function handleClearFiltersClick(
    setFilteredSalesChannelsListData: React.Dispatch<
        SetStateAction<[] | SalesChannelAPIResponse[]>
    >,
    setSalesChannelNameFilterValue: React.Dispatch<SetStateAction<string>>,
    setSalesChannelNameSuggestions: React.Dispatch<SetStateAction<string[]>>
): void {
    setFilteredSalesChannelsListData([])
    setSalesChannelNameFilterValue('')
    setSalesChannelNameSuggestions([])
}

export function getSalesChannelSuggestionsList(
    searchString: string,
    salesChannelData: SalesChannelAPIResponse[],
    setSalesChannelNameSuggestions: React.Dispatch<SetStateAction<string[]>>,
    numberOfSuggestions: number
): void {
    const filteredGroupsBySearchString = salesChannelData
        .map((salesChannel) => salesChannel.title)
        .filter((title) => title.toLowerCase().includes(searchString.toLowerCase()))
    const weightedOrderedProductNames = stringArrayFilterAndSortByMatcher(
        filteredGroupsBySearchString,
        searchString
    )
    setSalesChannelNameSuggestions(weightedOrderedProductNames.slice(0, numberOfSuggestions))
}

const SalesChannelManagement: React.FC<SalesChannelManagementProps> = ({
    apiClient,
    companyTier,
}) => {
    const [salesChannelData, setSalesChannelData] = useState<SalesChannelAPIResponse[] | null>(null)
    const [filteredSalesChannelListData, setFilteredSalesChannelListData] = useState<
        SalesChannelAPIResponse[] | []
    >([])
    const [fetchingSalesChannels, setFetchingSalesChannels] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [creatingSalesChannel, setCreatingSalesChannel] = useState<boolean>(false)
    const [newSalesChannelData, setNewSalesChannelData] = useState<SalesChannelAPIResponse | null>(
        null
    )
    const [newSalesChannelName, setNewSalesChannelName] = useState<string>('')
    const [createSalesChannelSuccess, setCreateSalesChannelSuccess] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string>('')
    const [apiGetErrorMessage, setApiGetErrorMessage] = useState<string | null>(null)
    const [apiCreateErrorMessage, setApiCreateErrorMessage] = useState<string | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [salesChannelNameFilterValue, setSalesChannelNameFilterValue] = useState<string>('')
    const [salesChannelNameSuggestions, setSalesChannelNameSuggestions] = useState<string[]>([])

    const turnOnAddSalesChannelButton = companyTier && PAID_COMPANY_TIERS.includes(companyTier)

    useEffect(() => {
        getSalesChannelsList(
            apiClient,
            setSalesChannelData,
            setApiGetErrorMessage,
            setFetchingSalesChannels
        )
    }, [apiClient])

    useEffect(() => {
        if (newSalesChannelName && !creatingSalesChannel && !newSalesChannelData) {
            createSalesChannel(
                apiClient,
                newSalesChannelName,
                setNewSalesChannelData,
                setApiCreateErrorMessage,
                setCreatingSalesChannel,
                setIsModalOpen
            )
        }
        setNewSalesChannelName('')
    }, [newSalesChannelName, creatingSalesChannel, newSalesChannelData, apiClient])

    useEffect(() => {
        if (newSalesChannelData && salesChannelData) {
            setSalesChannelData(sortByTitle([...salesChannelData, newSalesChannelData]))
            setCreateSalesChannelSuccess(true)
            setSuccessMessage(
                newSalesChannelData
                    ? `'${newSalesChannelData.title}' ${content.salesChannelCreated}`
                    : content.salesChannelCreated
            )
        }
        setNewSalesChannelData(null)
    }, [newSalesChannelData, salesChannelData])

    useEffect(() => {
        if (salesChannelData) {
            setFilteredSalesChannelListData(
                salesChannelData.filter((salesChannel) =>
                    salesChannel.title
                        .toLowerCase()
                        .includes(salesChannelNameFilterValue.toLowerCase())
                )
            )
            setCurrentPage(1)
        }
    }, [salesChannelNameFilterValue, salesChannelData])

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: { title: '' },
    })

    const addSalesChannelButtonId = 'open-add-sales-channel-modal-button'
    const addSalesChannelButtonRef = useRef<HTMLButtonElement | null>(null)

    return (
        <div className={styles.container}>
            <Heading heading='1'>{content.title}</Heading>
            {turnOnAddSalesChannelButton && (
                <div className={styles['sales-channel-add-wrapper']}>
                    {!fetchingSalesChannels && apiGetErrorMessage && (
                        <InfoBanner
                            bannerType='error'
                            text={apiGetErrorMessage}
                            id='api-error-banner'
                        />
                    )}
                    {!creatingSalesChannel && apiCreateErrorMessage && (
                        <InfoBanner
                            bannerType='error'
                            text={apiCreateErrorMessage}
                            id='api-error-banner'
                        />
                    )}
                    {createSalesChannelSuccess && !creatingSalesChannel && (
                        <InfoBanner
                            bannerType='success'
                            text={successMessage}
                            id='api-error-banner'
                            isCloseable
                        />
                    )}
                    <div className={styles['sales-channel-add']}>
                        <Text>{content.description}</Text>
                        <div>
                            <Button
                                id={addSalesChannelButtonId}
                                type='button'
                                text={content.addSalesChannelButton}
                                ref={addSalesChannelButtonRef}
                                onClick={(): void => {
                                    handleOnAddSalesChannelClick(
                                        setApiCreateErrorMessage,
                                        setIsModalOpen,
                                        setCreateSalesChannelSuccess,
                                        reset
                                    )
                                }}
                            />
                            <Modal
                                className={styles.wrapper}
                                headerText={content.modal.header}
                                isOpen={isModalOpen}
                                returnFocusId={addSalesChannelButtonId}
                                setClosed={(): void => {
                                    handleCloseModal(
                                        setIsModalOpen,
                                        reset,
                                        addSalesChannelButtonRef
                                    )
                                }}
                            >
                                <form
                                    className={styles.form}
                                    onSubmit={handleSubmit(
                                        (formData: CreateSalesChannelFormData): void =>
                                            setNewSalesChannelName(formData.title)
                                    )}
                                >
                                    <Controller
                                        control={control}
                                        name='title'
                                        rules={{
                                            required: true,
                                            minLength: 1,
                                            maxLength: 30,
                                            pattern: REGEX.COMPANY_NAME,
                                        }}
                                        render={({
                                            field: { onBlur, onChange, value },
                                        }): React.ReactElement => (
                                            <div>
                                                <LabelledInput
                                                    required={true}
                                                    htmlFor='title'
                                                    label={content.modal.titleLabel}
                                                    aria-describedby={content.modal.titleLabel}
                                                    disabled={creatingSalesChannel}
                                                    isErrored={!!errors.title}
                                                >
                                                    <TextInput
                                                        value={value}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        required={true}
                                                    />
                                                </LabelledInput>
                                                <FieldError
                                                    inputId='title'
                                                    showError={!!errors.title}
                                                    errorMessage={content.errors.title}
                                                />
                                            </div>
                                        )}
                                    />
                                    <div className={styles['button-wrapper']}>
                                        <Button
                                            type='button'
                                            flavour='tertiary'
                                            text={content.modal.cancelButton}
                                            onClick={(): void => {
                                                handleCloseModal(
                                                    setIsModalOpen,
                                                    reset,
                                                    addSalesChannelButtonRef
                                                )
                                            }}
                                        />
                                        <Button text={content.modal.confirmButton} type='submit' />
                                    </div>
                                </form>
                            </Modal>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles['sales-channel-filter']}>
                <Text>{content.filter.title}</Text>
                <div className={styles['sales-channel-filter-content']}>
                    <Autosuggest
                        openSuggestionsOnFocus={salesChannelNameFilterValue?.length > 0}
                        inputValue={salesChannelNameFilterValue}
                        setInputValue={(value): void => {
                            let input = value
                            if (typeof value === 'object') input = Object.values(value).join('')
                            setSalesChannelNameFilterValue(input)
                        }}
                        onBlur={(value): void => {
                            if (!value)
                                handleClearFiltersClick(
                                    setFilteredSalesChannelListData,
                                    setSalesChannelNameFilterValue,
                                    setSalesChannelNameSuggestions
                                )
                            if (value) setSalesChannelNameFilterValue(value)
                        }}
                        id='sales-channel-autosuggest'
                        getSuggestionValue={(groupTitle): string => {
                            return groupTitle
                        }}
                        onSuggestionSelected={(value: string): string => {
                            setSalesChannelNameFilterValue(value)
                            const filteredSalesChannels = salesChannelData
                                ? salesChannelData.filter((salesChannel) =>
                                      salesChannel.title.toLowerCase().includes(value.toLowerCase())
                                  )
                                : []
                            if (filteredSalesChannels)
                                setFilteredSalesChannelListData(filteredSalesChannels)
                            return value
                        }}
                        name='autosuggest-sales-channel-filter'
                        renderSuggestion={(value: string): React.ReactElement => {
                            return <span>{value}</span>
                        }}
                        onSuggestionsClearRequested={(): void => setSalesChannelNameSuggestions([])}
                        suggestionsData={salesChannelNameSuggestions}
                        suggestionsFetchRequest={(value: string): void => {
                            if (salesChannelData) {
                                getSalesChannelSuggestionsList(
                                    value,
                                    salesChannelData,
                                    setSalesChannelNameSuggestions,
                                    NUMBER_OF_SUGGESTIONS
                                )
                            }
                        }}
                        renderInputComponent={({
                            ...rest
                        }: RenderInputComponentProps): React.ReactElement => (
                            <LabelledInput
                                htmlFor='sales-channel-filter'
                                label={content.filter.title}
                                labelHidden
                                {...rest}
                            >
                                <TextInput />
                            </LabelledInput>
                        )}
                    />
                    <div>
                        <Button
                            text={content.filter.button}
                            type='button'
                            flavour='secondary'
                            onClick={(): void =>
                                handleClearFiltersClick(
                                    setFilteredSalesChannelListData,
                                    setSalesChannelNameFilterValue,
                                    setSalesChannelNameSuggestions
                                )
                            }
                        />
                    </div>
                </div>
            </div>
            <Card header={content.list.heading}>
                <div className={styles['sales-channel-content']}>
                    {fetchingSalesChannels && <LargeSpinner text={content.fetchingSalesChannels} />}
                    {!fetchingSalesChannels && salesChannelData && (
                        <SalesChannelList
                            salesChannelData={salesChannelsToDisplay(
                                filteredSalesChannelListData.length > 0
                                    ? filteredSalesChannelListData
                                    : salesChannelData,
                                currentPage
                            )}
                        />
                    )}
                    <div className={styles['sales-channel-pagination']}>
                        {!!salesChannelData && (
                            <TablePaginationButtons
                                handlePageChange={(selected: number): void =>
                                    handlePageClick(selected, setCurrentPage)
                                }
                                page={currentPage}
                                totalNumOfPages={
                                    filteredSalesChannelListData.length > 0
                                        ? Math.ceil(
                                              filteredSalesChannelListData.length /
                                                  SALES_CHANNELS_PER_PAGE
                                          )
                                        : Math.ceil(
                                              salesChannelData.length / SALES_CHANNELS_PER_PAGE
                                          )
                                }
                            />
                        )}
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default SalesChannelManagement
