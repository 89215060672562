import React, { useContext, useEffect, useRef, useState } from 'react'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { datadogLogs } from '@datadog/browser-logs'

import Breadcrumb from 'components/basics/Breadcrumb/Breadcrumb'
import CabinGrades from 'components/sections/cruise/SailingTabs/CabinGrades/CabinGrades'
import ErrorList from 'components/sections/app/ErrorList/ErrorList'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Itinerary from 'components/sections/cruise/SailingTabs/Itinerary/Itinerary'
import Heading from 'components/basics/Heading/Heading'
import LargeSpinner from 'components/basics/Spinners/LargeSpinner'
import ModifySearchBar from 'components/sections/cruise/ModifySearchBar/ModifySearchBar'
import SailingInfo from 'components/sections/cruise/SailingInfo/SailingInfo'
import Spacing from 'components/basics/Spacing/Spacing'
import Tabs from 'components/basics/Tabs/Tabs'

import { SailingContent } from 'api-data-models/SailingContentModel'
import { ROUTES } from 'components/sections/app/AppRoutes'
import {
    CRUISE_SEARCH_DATA_KEY,
    getCruiseSearchQueryInput,
} from 'components/pages/cruise/SearchPage'

import { buildApiVariables } from '../buildApiVariables.utils'
import { checkSailingParams } from './SailingLayout.utils'
import { createQueryParamString } from 'utils/create-query-param-string'
import { getDataFromLocalStorage } from 'utils/use-local-storage'
import { usePassengersInfo } from 'components/hooks/usePassengersInfo'
import { useApolloQuery } from 'components/hooks/useApolloQuery'
import { GET_SAILING_BY_CRUISE_ID } from 'graphql-queries/cruise/cruise-queries'
import { SUPPLIER_NAMES, SupplierCodes, VITALLY_EVENTS } from 'utils/constants'
import CustomerSuccess from 'services/customerSuccess/customerSuccess.service'
import { isTestEmailDomain } from 'utils/testing-handlers'
import { extractGlobalContextUserData } from 'utils/user-data-helpers/extract-user-data-fields'
import { FeatureToggleContext } from 'App'
import * as self from './SailingLayout'
import AlternativeSailingDates from 'components/sections/cruise/AlternativeSailingDates/AlternativeSailingDates'

import styles from './SailingLayout.module.css'
import allContent from 'content/content'

const sailingPageContent = allContent.cruise.sailingPage
const breadcrumbContent = allContent.app.breadcrumbs

type SailingLayoutProps = {
    apiClient: ApolloClient<NormalizedCacheObject>
}

const createLoadingText = (code: SupplierCodes): string => {
    const name = SUPPLIER_NAMES?.[code] || 'supplier'
    return `Contacting ${name} for pricing...`
}

type TabsItem = {
    label: string
    component: JSX.Element
}

export const getResultsUrls = (): any => {
    const previousSearchParams = getDataFromLocalStorage({ key: CRUISE_SEARCH_DATA_KEY })
    let resultsPageUrl = ROUTES.CRUISE_RESULTS
    let initialModifySearchParams

    if (previousSearchParams) {
        const queryInput = getCruiseSearchQueryInput(previousSearchParams)
        const cruiseSearchQueryParamsString = createQueryParamString(queryInput)
        resultsPageUrl = `${ROUTES.CRUISE_RESULTS}/?${cruiseSearchQueryParamsString}`
        initialModifySearchParams = getCruiseSearchQueryInput(previousSearchParams)
    }

    return { resultsPageUrl, initialModifySearchParams }
}

/** SailingLayout: Layout for the Sailing page */
const SailingLayout: React.FC<SailingLayoutProps> = ({ apiClient }: SailingLayoutProps) => {
    const featureToggles = useContext(FeatureToggleContext)
    const { userEmail, companyTier } = extractGlobalContextUserData(
        datadogLogs.getGlobalContext() as GlobalContextUserData
    )
    const useAltSailDates = featureToggles.TURN_ON_ALT_SAIL_DATES || isTestEmailDomain(userEmail)

    const [sailingSearchResult, setSailingSearchResult] = useState<SailingContent | undefined>()

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const { passengerConfigurationDataAsQueryVar } = usePassengersInfo()
    const queryVariables = buildApiVariables({
        passengerConfigurationDataAsQueryVar: passengerConfigurationDataAsQueryVar,
        queryParams: queryParams,
    })
    const storedCruiseId = useRef(queryVariables?.cruiseId)
    const hasCorrectParams = checkSailingParams(queryParams)
    const { resultsPageUrl, initialModifySearchParams } = self.getResultsUrls()

    const { result, loading, error, refetch } = useApolloQuery({
        client: apiClient,
        variables: queryVariables,
        query: GET_SAILING_BY_CRUISE_ID,
        source: 'SailingLayout - GET_SAILING_BY_CRUISE_ID',
        skip: !hasCorrectParams, // boolean to skip calling api when we know it'll fail due to lack of params
    })

    useEffect(() => {
        if (result?.data && !loading) {
            const sailingContent = new SailingContent(result.data.cruise)
            setSailingSearchResult(sailingContent)
            CustomerSuccess.track({
                properties: {
                    cruiseId: queryVariables?.cruiseId,
                    supplierCode: queryVariables?.supplierCode,
                },
                eventName: VITALLY_EVENTS.LIVE_PRICING_SAILING,
            })
        }
    }, [loading, queryVariables?.cruiseId, queryVariables?.supplierCode, result])
    // if result object exists, error is false, and sailingSearchResult is undefined --> then the model is processing

    //refetch when an alternative sailing was selected:
    useEffect(() => {
        if (queryVariables?.cruiseId && queryVariables.cruiseId !== storedCruiseId.current) {
            storedCruiseId.current = queryVariables.cruiseId
            refetch()
        }
    }, [queryVariables.cruiseId, refetch])

    const processingResultData =
        !!result?.data && !sailingSearchResult && !error && hasCorrectParams

    const hasGrades = !!sailingSearchResult?.allAvailableCabinTypes.length

    const breadCrumbItems = [
        { text: breadcrumbContent.search, url: ROUTES.CRUISE_SEARCH },
        { text: breadcrumbContent.results, url: resultsPageUrl },
        { text: breadcrumbContent.sailing },
    ]

    const tabs: TabsItem[] = []

    if (sailingSearchResult) {
        if (hasGrades) {
            tabs.push({
                label: sailingPageContent.gradesTabTitle,
                component: (
                    <CabinGrades key='cabin-grades' sailingSearchResult={sailingSearchResult} />
                ),
            })
        }
        tabs.push({
            label: sailingPageContent.itineraryTabTitle,
            component: <Itinerary key='itinerary' sailingSearchResult={sailingSearchResult} />,
        })
        if (useAltSailDates) {
            tabs.push({
                label: sailingPageContent.altSailingDatesTabTitle,
                component: (
                    <div key='alt-sail-dates' className={styles['alt-sail-dates-container']}>
                        <AlternativeSailingDates
                            isStarterTier={false} // Hard coded because you can't get to sailing page on Starter Tier
                            originallyChosenDate={sailingSearchResult.embarkDate}
                            cruiseId={queryVariables?.cruiseId}
                            supplierCode={queryVariables?.supplierCode}
                            roundedTopCorners
                        />
                    </div>
                ),
            })
        }
    }

    function handleModifySearch(paramsString: string): void {
        navigate(`${ROUTES.CRUISE_RESULTS}/?${paramsString}`)
    }

    const renderError = (): React.ReactNode | null => {
        if (!hasCorrectParams)
            return (
                <InfoBanner
                    id='error-banner-params'
                    text={sailingPageContent.errors.incorrectParams}
                    bannerType='error'
                />
            )
        if (error) {
            return <ErrorList errorsList={error} source='sailing-page' />
        }
        return null
    }

    return (
        <div className='general-container'>
            <div className={styles.sections}>
                <Breadcrumb urlList={breadCrumbItems} />
                <ModifySearchBar
                    companyTier={companyTier as CompanyTierTypes} // can push type since page is only accessible if you have a valid companyTier
                    queryParams={initialModifySearchParams}
                    handleModifySearch={handleModifySearch}
                />
                {loading && (
                    <LargeSpinner
                        text={createLoadingText(queryVariables?.supplierCode as SupplierCodes)}
                    />
                )}
                {!loading && (error || !hasCorrectParams) && renderError()}
                {!loading && sailingSearchResult && !processingResultData && (
                    <div>
                        <Heading heading='1'>{sailingSearchResult.cruiseName}</Heading>
                        <Spacing size='double' />
                        <div className={styles.sections}>
                            {!hasGrades && !error && (
                                <InfoBanner
                                    bannerType='error'
                                    text={sailingPageContent.errors.noPricesAvailable}
                                    id='error-banner-no-available-prices'
                                    buttonText='Go back to results'
                                    onButtonClick={(): void => navigate(resultsPageUrl)}
                                    isCloseable={false}
                                    logType='warn'
                                    source='sailing-page'
                                />
                            )}
                            <SailingInfo sailingSearchResult={sailingSearchResult} />
                            <StyledEngineProvider injectFirst>
                                <Tabs tabs={tabs} ariaLabel={'grades itinerary tabs'} />
                            </StyledEngineProvider>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SailingLayout
