import React, { useState } from 'react'
import Accordion from 'components/blocks/Accordion/Accordion/Accordion'
import InfoBanner from 'components/blocks/InfoBanner/InfoBanner'
import Modal from 'components/blocks/Modal/Modal'
import RateCodes from '../../RateCodes/RateCodes'
import { PriceBreakdown } from './PriceBreakdown/PriceBreakdown'
import { SailingContent } from 'api-data-models/SailingContentModel'
import CabinGradesTable from './CabinGradesTable/CabinGradesTable'
import createApolloClient from 'utils/apollo-client/create-apollo-client'

import styles from './CabinGrades.module.css'
import allContent from '../../../../../content/content'

const content = allContent.cruise.sailingPage.cabinGradesView

type CabinGradesProps = {
    /** A single sailing result object */
    sailingSearchResult: SailingContent
}

export const CabinGrades: React.FC<CabinGradesProps> = ({
    sailingSearchResult,
}: CabinGradesProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isPriceModalOpen, setIsPriceModalOpen] = useState(false)
    const [priceModalData, setPriceModalData] = useState<Record<string, any>>()
    const [clickedButtonId, setClickedButtonId] = useState('')
    const { currency, cruiseId, supplierCode } = sailingSearchResult
    const cabinByType = sailingSearchResult.cabinsByType || []
    const allRateCodes = sailingSearchResult.allRateCodes || []
    const apiClient = createApolloClient(process.env.REACT_APP_BACKEND_SERVICE_URL)

    const cabinTypePriceAccordions = cabinByType.map((cabinTypeGroup, index) => {
        return (
            <Accordion
                key={cabinTypeGroup.type}
                titleSize='3'
                titleLevel='2'
                darkHeading={true}
                isPadded={true}
                baseId={cabinTypeGroup.type}
                title={cabinTypeGroup.type}
                className={styles.accordions}
                startOpen={index === 0}
            >
                <CabinGradesTable
                    cabinTypeGroup={cabinTypeGroup}
                    cruiseId={cruiseId}
                    setPriceModalData={setPriceModalData}
                    supplierCode={supplierCode}
                    setClickedButtonId={setClickedButtonId}
                    currency={currency}
                    setIsPriceModalOpen={setIsPriceModalOpen}
                    allRateCodes={allRateCodes}
                />
            </Accordion>
        )
    })

    return (
        <div className={styles.container}>
            <InfoBanner
                bannerType='info'
                text={content.priceInfoBanner}
                id='price-based-on-two-info'
                isCloseable={true}
            />
            <RateCodes rateCodes={sailingSearchResult.allRateCodes} />
            {cabinTypePriceAccordions}
            <Modal
                isOpen={isPriceModalOpen}
                headerText={content.breakdown.header}
                returnFocusId={clickedButtonId}
                setClosed={(): void => setIsPriceModalOpen(false)}
                loading={isLoading}
            >
                <PriceBreakdown
                    cabinGradeCode={priceModalData?.cabinGrade?.cabinGradeCode}
                    rateCode={priceModalData?.rateCode?.code}
                    cabinGradeDescription={priceModalData?.cabinGrade?.description}
                    rateCodeDescription={priceModalData?.rateCode?.description}
                    currency={currency}
                    supplierCode={priceModalData?.supplierCode}
                    cruiseId={priceModalData?.cruiseId}
                    setIsLoading={setIsLoading}
                    priceProps={{
                        military: priceModalData?.rateCode?.military,
                        residency: priceModalData?.rateCode?.residency,
                        refundPolicy: priceModalData?.rateCode?.refundPolicy,
                    }}
                    apiClient={apiClient}
                />
            </Modal>
        </div>
    )
}

export default CabinGrades
